import React, { useEffect, useContext, useRef } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
// import { TweenLite, Power3 } from "gsap";

import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import Clients from "../../components/common/Clients";
import InteractiveVideo from "../../components/common/InteractiveVideo";
// import image from "../../assets/img/products/Permit to Work.jpg";

import { StaticImage } from "gatsby-plugin-image";

const PermitToWork = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Layout>
      <Seo
        title='Permit to Work | VIRSAT - Virtual Reality Safety Training'
        description='Permit to work focuses on safe execution of activities to prevent harm to workers. A series of animations focused on the job at hand & its risks is available.'
      />
      <PageBanner title='Permit to Work' subtitle='Inform about hazards' />
      <section
        className='main-container single-product hide-container'
        ref={container}
      >
        <div className='row top-pad bottom-pad fd-col less-mar'>
          <div className='column'>
            <p className='medium reduce_width dark no-mar center'>
              Permit to work focuses on safe execution of activities to prevent
              harm to workers.
            </p>
            <hr className='site-border' />
          </div>
          <div className='column details-container'>
            <div className='details'>
              <p>
                A series of animations focused on the job at hand and its risks
                is available. It can be viewed by those who prepare the job,
                those who need to execute the job and/or those who approve and
                supervise the job. The animations can be delivered through an
                electronic Permit to Work systems as and when needed.
              </p>
              <p>
                <strong>
                  These animations are based on actual fatal incidents.
                </strong>
              </p>
            </div>
            <div className='image-container'>
              <StaticImage
                src='../../assets/img/products/Permit to Work.jpg'
                alt='Permit to Work'
                placeholder='blurred'
                objectFit
              />
              {/* <img src={image} alt='Permit to Work' /> */}
            </div>
          </div>
        </div>
      </section>
      <Clients />
      <InteractiveVideo />
    </Layout>
  );
};

export default PermitToWork;
